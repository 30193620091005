<template>
  <div>
    <b-container fluid class="content">
      <b-card no-body>
        <b-tabs card>
          <b-tab :title="$t('Reserve Setup')" active>
            <b-form class="rvsetting_form">
              <b-row>
                <b-col cols="2" class="tm_head">
                  {{ $t("Name") }}
                </b-col>
                <b-col cols="2" class="tm_head">
                  {{ $t("Time") }}
                </b-col>
                <b-col class="tm_head">
                  {{ $t("Week") }}
                </b-col>
                <b-col cols="1" class="tm_head">
                  <b-button
                    variant="success"
                    size="sm"
                    @click="do_reservation(null)"
                    >{{ $t("Add") }}</b-button
                  >
                </b-col>
              </b-row>
              <b-row v-for="(rvsetting, idx) in rvsettinglist" :key="idx" class="mt-1">
                <b-col cols="2" class="tm_body">
                  {{ rvsetting.name }}
                </b-col>
                <b-col cols="2" class="tm_body">
                  {{ rvsetting.tm }}
                </b-col>
                <b-col class="tm_body">
                  <span v-if="rvsettinglist[idx].d0==1" class="mr-1">
                    {{ $t("Sunday") }}
                  </span>
                  <span v-if="rvsettinglist[idx].d1==1" class="mr-1">
                    {{ $t("Monday") }}
                  </span>
                  <span v-if="rvsettinglist[idx].d2==1" class="mr-1">
                    {{ $t("Tuesday") }}
                  </span>
                  <span v-if="rvsettinglist[idx].d3==1" class="mr-1">
                    {{ $t("Wednesday") }}
                  </span>
                  <span v-if="rvsettinglist[idx].d4==1" class="mr-1">
                    {{ $t("Thursday") }}
                  </span>
                  <span v-if="rvsettinglist[idx].d5==1" class="mr-1">
                    {{ $t("Friday") }}
                  </span>
                  <span v-if="rvsettinglist[idx].d6==1" class="mr-1">
                    {{ $t("Saturday") }}
                  </span>
                </b-col>
                <b-col cols="1" class="tm_body">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="do_reservation(rvsetting)"
                    >{{ $t("Edit") }}</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </b-tab>
          <b-tab :title="$t('Open Table')">
            <b-form class="rvsetting_form">
              <b-row>
                <b-col class="tm_head">
                  {{ $t("Name") }}
                </b-col>
                <b-col class="tm_head">
                  {{ $t("Quntity") }}
                </b-col>
                <b-col class="tm_head">
                  {{ $t("Minimum") }}
                </b-col>
                <b-col class="tm_head">
                  {{ $t("Maximum") }}
                </b-col>
                <b-col class="tm_head">
                  <b-button variant="success" size="sm" @click="do_opens(null)">{{
                    $t("Add")
                  }}</b-button>
                </b-col>
              </b-row>
              <b-row
                v-for="(opentb, idx) in opentablelist"
                :key="idx"
                class="mt-1"
              >
                <b-col class="tm_body">
                  {{ opentb.name }}
                </b-col>
                <b-col class="tm_body">
                  {{ opentb.open_num }}
                </b-col>
                <b-col class="tm_body">
                  {{ opentb.min_num }}
                </b-col>
                <b-col class="tm_body">
                  {{ opentb.max_num }}
                </b-col>
                <b-col class="tm_body">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="do_opens(opentb)"
                    >{{ $t("Edit") }}</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </b-tab>
        </b-tabs>
        <b-row class="text-center mt-3 mb-3">
          <b-col>
            <el-button type="primary" @click="go_back">{{ $t("Back") }}</el-button>
          </b-col>
        </b-row>
      </b-card>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
    <b-modal
      ref="modal-rvsetting"
      size="lg"
      :title="$t('Reserve Setup')"
      :okTitle="$t('Submit')"
      :cancelTitle="$t('Cancel')"
      @ok="submit_rvsetting"
    >
      <div>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          :label="$t('Name')"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="cur_rvsetting.name"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          :label="$t('Time')"
          label-for="input-start-tm"
        >
          <el-time-select
            id="input-start-tm"
            class="input-tm"
            v-model="cur_rvsetting.tm"
            :picker-options="{ start: '00:00', end: '23:30', step: '00:30' }"
            :placeholder="$t('Start Time')"
          ></el-time-select>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          :label="$t('Week')"
          label-for="input-end-tm"
        >
          <el-checkbox
            :label="$t('Sunday')"
            v-model="cur_rvsetting.d0"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('Monday')"
            v-model="cur_rvsetting.d1"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('Tuesday')"
            v-model="cur_rvsetting.d2"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('Wednesday')"
            v-model="cur_rvsetting.d3"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('Thursday')"
            v-model="cur_rvsetting.d4"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('Friday')"
            v-model="cur_rvsetting.d5"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('Saturday')"
            v-model="cur_rvsetting.d6"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          :label="$t('Open Table')"
          label-for="input-end-tm"
        >
          <el-checkbox-group v-model="cur_rvsetting.tablelist">
            <el-checkbox
              v-for="otb in opentablelist"
              :label="otb.name"
              :key="otb.id"
              >{{ otb.name }}</el-checkbox
            >
          </el-checkbox-group>
        </b-form-group>
      </div>
    </b-modal>
    <b-modal
      ref="modal-opens"
      :title="$t('Open Table')"
      :okTitle="$t('Submit')"
      :cancelTitle="$t('Cancel')"
      @ok="submit_opens"
    >
      <div>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          :label="$t('Name')"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="cur_opentable.name"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          :label="$t('Quntity')"
          label-for="input-open-num"
        >
          <b-form-input
            id="input-open-num"
            type="number"
            min="1"
            v-model="cur_opentable.open_num"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          :label="$t('Minimum')"
          label-for="input-min-num"
        >
          <b-form-input
            id="input-min-num"
            type="number"
            min="1"
            v-model="cur_opentable.min_num"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          :label="$t('Maximum')"
          label-for="input-max-num"
        >
          <b-form-input
            id="input-max-num"
            type="number"
            min="1"
            v-model="cur_opentable.max_num"
          ></b-form-input>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>


<script>
import {
  Table,
  TableColumn,
  Button,
  Checkbox,
  CheckboxGroup,
  Dialog,
  TimeSelect,
} from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import ButtonCheckbox from "../../components/ButtonCheckbox.vue";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Dialog.name]: Dialog,
    [TimeSelect.name]: TimeSelect,
    ButtonCheckbox,
    flatPicker,
  },
  data() {
    return {
      store_id: 0,
      store: {},
      rvsettinglist: [],
      opentablelist: [],

      cur_rvsetting: {
        id: 0,
        name: "",
        tm: "",
        tablelist: [],
        d0: 0,
        d1: 0,
        d2: 0,
        d3: 0,
        d4: 0,
        d5: 0,
        d6: 0,
      },
      new_rvsetting: {
        id: 0,
        name: "",
        tm: "",
        tablelist: [],
        d0: 0,
        d1: 0,
        d2: 0,
        d3: 0,
        d4: 0,
        d5: 0,
        d6: 0,
      },
      cur_opentable: {
        id: 0,
        name: "",
        open_num: 1,
        min_num: 1,
        max_num: 4,
      },
      new_opentable: {
        id: 0,
        name: "",
        open_num: 1,
        min_num: 1,
        max_num: 4,
      },

      token: "",
      user_id: 0,
    };
  },
  methods: {
    go_back() {
      this.$router.push("/store");
    },
    do_reservation(opens) {
      if (opens) {
        this.cur_rvsetting.id = opens.id;
        this.cur_rvsetting.name = opens.name;
        this.cur_rvsetting.tm = opens.tm;
        this.cur_rvsetting.tablelist = JSON.parse(opens.tablelist);
        this.cur_rvsetting.d0 = parseInt(opens.d0);
        this.cur_rvsetting.d1 = parseInt(opens.d1);
        this.cur_rvsetting.d2 = parseInt(opens.d2);
        this.cur_rvsetting.d3 = parseInt(opens.d3);
        this.cur_rvsetting.d4 = parseInt(opens.d4);
        this.cur_rvsetting.d5 = parseInt(opens.d5);
        this.cur_rvsetting.d6 = parseInt(opens.d6);
      } else {
        this.cur_rvsetting = JSON.parse(JSON.stringify(this.new_rvsetting));
      }
      this.$refs["modal-rvsetting"].show();
    },
    async submit_rvsetting() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("store_id", this.store_id);

      var that = this;
      for (var key in this.cur_rvsetting) {
        if (key == 'tablelist') {
          bodyFormData.append(key, JSON.stringify(this.cur_rvsetting[key]));
        } else {
          bodyFormData.append(key, this.cur_rvsetting[key]);
        }
      }

      axios({
        method: "post",
        url: "/Api/Web/Reserve/reserve_change",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.rvsettinglist = rt.data.rvsettings;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    do_opens(opens) {
      if (opens) {
        this.cur_opentable.id = opens.id;
        this.cur_opentable.name = opens.name;
        this.cur_opentable.open_num = opens.open_num;
        this.cur_opentable.min_num = opens.min_num;
        this.cur_opentable.max_num = opens.max_num;
      } else {
        this.cur_opentable = JSON.parse(JSON.stringify(this.new_opentable));
      }
      this.$refs["modal-opens"].show();
    },
    async submit_opens() {
      for (var i = 0; i < this.opentablelist.length; i++) {
        if (this.opentablelist[i].name == this.cur_opentable) {
          alert("Name existed");
          return;
        }
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("store_id", this.store_id);

      var that = this;
      for (var key in this.cur_opentable) {
        bodyFormData.append(key, this.cur_opentable[key]);
      }

      axios({
        method: "post",
        url: "/Api/Web/Reserve/opentable",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.opentablelist = rt.data.opentables;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    async get_reserve_setting() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("store_id", this.store_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Reserve",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.store = rt.data.store;
              that.rvsettinglist = rt.data.rvsettings;
              that.opentablelist = rt.data.opentables;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/weblogin");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      this.store_id = this.$route.query.store_id;
      if (!this.token || !this.user_id || !this.store_id) {
        this.$router.push("/weblogin");
        return;
      }

      return this.get_reserve_setting();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
.tab-content > .tab-pane {
  background-color: #ffffff;
}
.tm_head {
  font-weight: bold;
}
.input-tm {
  width: 50% !important;
}
</style>
